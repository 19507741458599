.bg {
  &-green-bg {
    background: rgba(223, 250, 232, 0.3);
  }

  &-grey {
    background: rgba(212, 217, 226, 1);
  }

  &-grey-bg {
    background: rgba(230, 233, 240, 0.35);
  }
}

.text {
  &-300 {
    color: rgba(0, 17, 34, 0.66);
  }
}