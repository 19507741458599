.dropdown-custom {
  cursor: pointer;
}

.dropdown-menu-custom {
  z-index: 5000;
  position: absolute;
  top: 40px;
  display:flex;
  flex-direction: column;
  right: -100px;
  height: 0;
  opacity: 0;
  transition: 0.3s height ease-in-out, 0.3s opacity ease-in-out;
  background: white;
  pointer-events: none;
  &.show-custom {
    opacity: 1;
    pointer-events: all;
    height: auto;
    & .button-dropdown_wrapper {
      opacity: 1;
      padding: 10px 16px;
      pointer-events: all;
    }
  }
  & .button-dropdown_wrapper {
    opacity: 0;
    padding: 0;
    pointer-events: none;
    transition: 0.3s padding ease-in-out, 0.3s opacity ease-in-out;
  }
}

.logout,
.wallet-disconnect,
.dropdown-wallet-connect-wrapper,
.collapsable {
  opacity: 1;
  height: auto;
  pointer-events: all;
  & * {
    opacity: inherit;
    height: auto;
    pointer-events: all;
  }

  &.hide{
    height: 0;
    opacity: 0;
    pointer-events: none;
    & * {
      height: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.wallet-connection-status {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  &.connected {
    background: #3ac267;
  }
  &.disconnected {
    background: #ffd900;
  }
  &.error {
    background: #DF2059;
  }
}
