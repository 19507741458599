//------------------------------------------
//  Acquiring dropdown
//------------------------------------------

.acquiring-shops {
  cursor: pointer;
  margin-right: 24px;

  &.dropdown-disabled {
    background: rgba(230, 233, 240, 0.35);

    .dropdown-toggle::after {
      display: none;
    }
  }

  .dropdown-toggle {
    margin: 0 auto 0 0;
  }

  .acquiring-dropdown-select {
    max-height: 265px;
    min-width: 265px;
    overflow-x: scroll;
    border-radius: 8px;
    border: 0;
    box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.08);
    padding: 12px 0;
    transform: translate(0, 40px) !important;

    .dropdown-item {
      cursor: pointer;
      padding: 12px;
      background: white;

      &:hover {
        background: rgba(230, 233, 240, 0.35);
      }
    }

    .dropdown-active {
      background: rgba(233, 240, 253, 0.3);

      .icon__wrapper {
        background: url(svg-encode($done-icon)) no-repeat center center;
      }
    }
  }
}