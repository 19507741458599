%default_icon_bg {
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.icon-bg {
  &-warning {
    @extend %default_icon_bg;
    background: rgba(255, 247, 209, 0.3);
  }
}