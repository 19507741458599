.block-user-body {
  font-family: 'Montserrat', sans-serif, sans-serif;
  padding: 0;
  margin: 0;
  font-size: 18px;
  background-image: url("../img/block-user/background_main-block.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -20px;
  background-position-x: center;


  $--ui-color-blue: #4482FF;
  $--ui-color-blue-dark: #1849A9;
  $--ui-color-blue-med: #92b4f6;
  $--ui-color-blue-light: #EEF4FF;
  $--ui-color-blue-bg: #e9f0fd;

  $--ui-color-500: #172133;
  $--ui-color-400: #57626D;
  $--ui-color-300: #707A83;

  $--ui-color-inversive-500: #ffffff;
  $--ui-color-inversive-400: #f5f7fa;

  $--ui-color-auth-background: #f8f8f8;

  $--ui-color-black-light: #48556e;
  $--ui-color-gray-dark: #8d95a5;

  $--ui-color-gray: #d4d9e2;
  $--ui-color-gray-light: #e6e9f0;
  $--ui-color-grey-med: #8D95A5;


  $--ui-color-green: #3AC267;
  $--ui-color-green-dark: #268045;
  $--ui-color-green-light: #93DDAC;
  $--ui-color-green-bg: rgba(223, 250, 232, 0.3);


  $--ui-color-red: #DF2059;

  // -----------------------------------
  //              Helper
  //-----------------------------------

  @function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
  }

  $encoding-reference: (
                  ('<', '%3C'),
                  ('>', '%3E'),
                  ('"', '\''),
                  ('#', '%23'),
                  ('&', '%26')
  );

  @function svg-encode($svg) {
    @each $char, $encoded in $encoding-reference {
      $svg: str-replace($svg, $char, $encoded);
    }
    @return 'data:image/svg+xml,' + $svg;
  }

  .header {
    padding: 26px 26px;

    .logo img {
      margin-right: 14px;
    }

    .container {
      flex-wrap: wrap;

      a {
        text-decoration: none;
        cursor: pointer;
        margin-right: 40px;
      }

      a:last-child {
        margin-right: 0;
      }

      a:hover {
        background-color: transparent;
        color: #172133;
      }

    }
  }

  .navigation-menu {
    height: 24px;
    width: 24px;
    margin: auto 0 auto auto;
    display: none;
  }

  .offcanvas-end {
    box-shadow: 0 5px 10px 0 #301F851A;
    border: 0;
    width: 285px!important;
  }

  .offcanvas-body {
    box-shadow: 0 5px 10px 0 #301F851A;
    border: 0;
    padding: 34px 20px;

    .navigation-menu {
      margin-bottom: 20px;
    }

    .mobile-navigation {
      .navigation-link {
        text-decoration: none;
        margin-bottom: 24px;
      }
    }
  }

  .landing-footer {
    background-color: #4482FF;

    .container {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 56px;
      padding-bottom: 72px;

      .left-section {
        width: 282px;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-right: 12px;
          }
        }

        a {
          margin-top: 10px;
          text-decoration: none;

          img {
            margin-right: 5px;
          }
        }

        a:hover {
          background-color: unset !important;
          color: #FFFFFF;
        }

        .company-name {
          text-transform: uppercase;
          margin: 14px 0;
        }
      }

      .right-section {
        width: 270px;

        .footer-social {
          display: flex;
          flex-direction: row;
          align-items: center;

          a {
            text-decoration: none;
            cursor: pointer;
            margin-right: 15px;
          }

          a:hover {
            background-color: unset !important;
            color: #FFFFFF;
          }
        }

        .docs-container {
          margin-top: 16px;

          .doc-el {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            cursor: pointer;

            img {
              margin-right: 8px;
            }
          }

          .doc-el:hover {
            background-color: unset !important;
            color: #FFFFFF;
          }

          .doc-el:nth-child(even) {
            margin: 10px 0;
          }
        }

      }

    }
  }

  .text-align-center {
    text-align: center;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  //------------------------------------
  //             Shortcuts
  //------------------------------------

  %bold-heading {
    font-style: normal;
    font-weight: 600;
  }

  %bold-medium {
    font-style: normal;
    font-weight: 500;
  }


  %text-bold {
    font-style: normal;
    font-weight: 600;
  }

  %text-regular {
    font-style: normal;
    font-weight: 400;
  }

  %one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  %bold-extra {
    font-style: normal;
    font-weight: 700;
  }

  //------------------------------------
  //          Service Styles
  //------------------------------------

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  html {
    padding: 0;
    margin: 0;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .landing {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  //-----------------------------------
  //            Typography
  //-----------------------------------

  .h2 {
    @extend %bold-heading;
    font-size: 1.67em;
    line-height: 133%;
  }

  .h3 {
    @extend %bold-heading;
    font-size: 1.11em;
    line-height: 140%;
  }

  .h4 {
    @extend %bold-heading;
    font-size: 1.11em;
    line-height: 1.55em;
  }

  .h5 {
    @extend %bold-medium;
    font-size: 1em;
    line-height: 1.33em;
  }

  .body {

    &-13 {
      @extend %text-regular;
      font-size: 13px;
      line-height: 20px;

      &-bold {
        @extend %text-bold;
        font-size: 13px;
        line-height: 20px;
      }
    }

    &-15 {
      @extend %text-regular;
      font-size: 15px;
      line-height: 24px;

      &-bold {
        @extend %text-bold;
        font-size: 15px;
        line-height: 24px;
      }
    }

    &-18 {
      @extend %text-regular;
      font-size: 18px;
      line-height: 28px;

      &-bold {
        @extend %text-bold;
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-32 {
      @extend %text-regular;
      font-size: 32px;
      line-height: 38.5px;

      &-bold {
        @extend %bold-extra;
        font-size: 32px;
        line-height: 38.5px;
      }
    }

    &-48 {
      @extend %text-regular;
      font-size: 48px;
      line-height: 59px;

      &-bold {
        @extend %text-bold;
        font-size: 48px;
        line-height: 59px;
      }
    }
  }

  .text-64-extra-bold {
    @extend %bold-extra;
    font-size: 64px;
    line-height: 78.02px;
  }

  .color-blue-med {
    color: #92B4F6;
  }

  //-----------------------------------
  //              Colors
  //-----------------------------------

  .color {
    &-blue {
      color: $--ui-color-blue;
    }

    &-black {
      color: #222222;
    }

    &-text-primary {
      color: #2E2E3A;
    }

    &-300 {
      color: $--ui-color-300;
    }

    &-400 {
      color: $--ui-color-400;
    }

    &-500 {
      color: $--ui-color-500;
    }

    &-green {
      color: #268045;
    }

    &-inversive-500 {
      color: $--ui-color-inversive-500;
    }
  }

  // -----------------------------------
  //              Button
  //-----------------------------------

  /*.btn__default-primary-rounded, */.btn__default-light-rounded {
                                       @extend %one-line;
                                       background: $--ui-color-inversive-500;
                                       border: 1px solid $--ui-color-inversive-500;
                                       border-radius: 39px;
                                       padding: 12px 24px;

                                       .btn__default-text {
                                         color: $--ui-color-blue;
                                       }
                                     }

  .btn__default-primary {
    @extend %one-line;
    background: $--ui-color-blue;
    border: 1px solid $--ui-color-blue;
    border-radius: 8px;
    padding: 12px 24px;
    box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
    text-decoration: none;
    cursor: pointer;

    .btn__default-text {
      color: $--ui-color-inversive-500;
    }
  }

  .btn__default-primary-light {
    @extend %one-line;
    background: #FFFFFF;
    border: 1px solid #EEF4FF;
    border-radius: 8px;
    padding: 12px 24px;
    box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
    text-decoration: none;
    cursor: pointer;

    .btn__default-text {
      color: $--ui-color-blue;
    }
  }

  .btn__default-primary:hover {
    background-color: #4482FF;
    color: transparent;
  }

  // -----------------------------------
  //              Modal
  //------------------------------------

  .blur-portal {
    display: none;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(149, 166, 195, 0);
    backdrop-filter: blur(0px);
    box-shadow: none;
    transition: .3s all ease-in-out;
    opacity: 0;

    &.active {
      display: flex;
    }

    a, button {
      text-decoration: none;
      cursor: pointer;
      width: 310px;
      border-radius: 39px;

      span {
        margin: auto;
      }
    }

    a:hover {
      background-color: $--ui-color-blue;
      color: transparent;
    }


    &.animate {
      opacity: 1;
      background: rgba(149, 166, 195, 0.2);
      backdrop-filter: blur(10px);
    }
  }

  .blur-modal {
    background-color: white;
    z-index: 1000;
    border-radius: 30px;
    padding: 56px;
    box-shadow: 0 15px 50px 0 #5086E033;
    position: relative;

    p {
      text-align: center;
    }

    .modal__close {
      position: absolute;
      top: 32px;
      right: 32px;
      height: 48px;
      width: 48px;
      border-radius: 24px;
      border: 1px solid $--ui-color-blue-light;
    }
  }

  // -----------------------------------
  //              Main
  //------------------------------------

  .landing-block {

    a {
      text-decoration: none;
      cursor: pointer;
      margin-right: 40px;
    }

    a:last-child {
      margin-right: 0;
    }

    .account-blocked-section {
      padding-bottom: 50px;

      .container {
        .left-section {
          max-width: 526px;
          display: flex;
          flex-direction: column;
          justify-content: center;


        }

        img {
          max-width: 570.3px;
          height: 515.46px;
        }
      }
    }

    .why-me-blocked-section {
      background: #F8FBFE;
      padding: 100px 0;

      .left-section {
        max-width: 526px;
      }

      .why-item {
        padding: 20px 24px;
        background: #FFFFFF;
        box-shadow: 0 0 40px rgba(19, 51, 146, 0.15);
        border-radius: 16px;
        align-items: center;

        img {
          width: 60px;
          height: 56.07px;
          margin-right: 24px;
        }
      }
    }

    .how-unlock {
      padding: 100px 0;

      .how-unlock-container {
        padding: 0 225px;
      }

      .form-unlock {
        padding: 100px;
        background: #FFFFFF;
        box-shadow: 0 0 40px rgba(19, 51, 146, 0.15);
        border-radius: 16px;
        display: flex;
        flex-direction: column;

        .inline-input__wrapper {
          min-width: 315px;
        }
      }

      .list-documents {
        background: #FFFFFF;
        box-shadow: 0 0 40px rgba(19, 51, 146, 0.15);
        border-radius: 16px;
        padding: 40px;


        .header-list-documents {

          .left-section {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              width: 64px;
              height: 59.81px;
              margin-right: 24px;
            }
          }

          .triangle-dropdown {
            width: 10px;
            height: 6px;
          }
        }

        .body-list-documents {
          padding-top: 40px;
          display: none;

          .photo-passport, .photo-contract, .operation-explanation {
            display: flex;
            flex-direction: column;

          }
        }

        &.active {
          .body-list-documents {
            display: flex;
          }

          .triangle-dropdown {
            transform: rotate(180deg);
          }
        }
      }
    }


  }

  @media(max-width: 1320px) {
    .landing-block .how-unlock .form-unlock .inline-input__wrapper {
      width: 100%;
    }


    .form-first-row, .form-second-row {
      flex-wrap: wrap;
      gap: 32px;
    }
  }

}
