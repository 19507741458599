.qr-wrapper {
  border: 1px solid #4482FF;
  border-radius: 24px;
  width: 160px;
  height: 160px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-button {
  width: 56px;
  height: 56px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.grey-social {
    background-color: #8D95A5;
  }
  &.blue-social {
    background-color: #4482FF;
  }
  &.telegram-social {
    background-color: #29A9EB;
  }
  &.whatsapp-social {
    background-color: #25D366;
  }
}