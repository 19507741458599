.modal__loader-small .loader {
  width: 16px;
  height: 16px;
  border-width: 4px;
}

svg {
  vertical-align: baseline;
}

input[type="password"]::-ms-reveal {
  display: none;
}

.clicked {
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}
.inline-input:disabled {
  opacity: .7;
}
