.card__active {
  border: 2px solid rgba(146, 180, 246, 1);
}

.card__inactive {

  &.card__item .current__value {
    color: #172133AA;
  }

  &.card__item .current__value.big-num {
    color: #172133AA;
  }

  &.card__item .card__header .wallet {
    color: #172133AA;
  }

}