.exchange-container {
  width: 177px;

  .exchange-name {
    width: 65px;
    margin-right: 15px;
  }

  .exchange-item {
    flex:1;
  }

  .buy-container img {
    margin-right: 24px;
    position: relative;
    bottom: -10px;
    margin-left: 4px;
  }

  .sell-container img {
    margin-right: 24px;
    position: relative;
    bottom: -10px;
    margin-left: 4px;
  }

  .buy-container img:last-child {
    margin-right: 0;
  }


  .sell-container img:last-child {
    margin-right: 0;
  }

  .text-end {
    margin-right: 4px;
  }
  div p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
