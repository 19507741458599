// tariff modal
.tariff-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .tariff-limit {
    flex:1;
  }
}
.progress-bar {
  background: #E6E9F0;
  border-radius: 20px;
  height: 4px;
  overflow: hidden;
  div {
    height: 100%;
  }
  .progress-green {
    background: #3AC267;
  }
  .progress-yellow {
    background: #FFD900;
  }
  .progress-red {
    background: #DF2059;
  }
}

.tariff-detailed-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .tariff-detail-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tariff-icon-container {
    margin-right: 8px;
    padding: 8px;
    background-color: #EEF4FF;
    border-radius: 8px;
  }
  .horizontal-line {
    border-top: 1px dashed #D4D9E2;
    flex: 1;
    margin: 0 16px;
    height: 0;
  }
}

.income-icon .tariff-icon-container {
  background: rgba(223, 250, 232, 0.3);
}
.tariff-warning-icon .tariff-icon-container {
  background: rgba(255, 247, 209, 0.3);
}

.limit-modal {
  cursor: pointer;
}

.dropdown-menu {
  border: none;
}
.tariff-modal-body {
  overflow-y: scroll;
  max-height: 70vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}