
.custom-select-container {
  height: 64px;
  width: 306px;
  background: #EEF4FF;
  border-radius: 12px;
  padding: 16px 24px;
  border: none;
}
.custom-select-opener {
  padding: 0;
  background-color: transparent;
  &:focus-visible {
    border:none
  }
}

.custom-select-panel {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  top:72px;
  left: 0;
  background: #FFFFFF;
  /* Shadow/popup */

  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  .custom-select-option.has-focus, .custom-select-option.is-selected {
    background: #EEF4FF;
  }
  .custom-select-option.is-selected::before {
    content: none;
  }
}

.customSelect.is-open {
  .custom-select-panel {
    padding: 12px 0;
  }
}

.destination {
  width: 306px;
}

.transfer__inputs {
  padding: 24px;
  background: #F5F7FA;
  border-radius: 12px;
  width: 746px;
}

.transfer-value {
  width: 306px;
}

img, svg {
  vertical-align: baseline;
}