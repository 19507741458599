.tariff-card {
  background-color: #F5F7FA;
  border-radius: 24px;
  height: calc(100vh - 330px);
  max-height: 700px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  &.payment-tariff {
    background-color: #4482FF;

    .status-button {
      background-color: white;
    }

    .horizontal-line {
      margin-top: 8px;
      border-top: 1px solid white;
      width: 100%;
      height: 0;
    }

    .tariff-footer {
      background-color: #4482FF;
    }
  }

  .tariff-header {
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: row;

    .tariff-logo {
      width: 82px;
      height: 76px;
      margin-right: 24px;
    }
  }

  .tariff-body {
    padding: 0 24px;
  }

  .tariff-property {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    p:first-child {
      margin-right: 16px;
    }

    .line-separator {
      border-top: 1px dashed #D4D9E2;
      height: 0;
      flex: 1
    }

    p:last-child {
      margin-left: 16px;
    }
  }

  .tariff-footer {
    padding: 24px;
    position: sticky;
    bottom: 0;
    box-shadow: 5px 0 20px rgba(48, 31, 133, 0.3);

    background-color: #F6F7FA;
  }

  &.payment-status__disabled {
    .tariff-header {
      .h2 {
        color: #707A83;
      }

      .body-15-bold {
        color: #707A83;
      }

    }

    .tariff-footer {
      background: linear-gradient(0deg, rgba(230, 233, 240, 0.35), rgba(230, 233, 240, 0.35)), #FFFFFF;
      box-shadow: 5px 0px 20px rgba(48, 31, 133, 0.1);

      .btn__default-text {
        @extend %bold-heading;
        font-size: 0.83em;
      }
    }
  }

  &.payment-status__disabled-bought {
    background: #F5F7FA;
    opacity: 0.4;
  }

  &.payment-tariff_bought {

    .tariff-body {
      .text-username {
        display: flex;
        justify-content: end;
      }
    }

    .tariff-footer {
      box-shadow: none;
    }

  }
}