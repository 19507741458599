.mass_error_table {
  .table-row {
    height: 56px;
    border-bottom: 1px solid rgba(167, 193, 235, .3);

    .table-item {
      padding: 6px 12px;
      position: relative;
      flex: 1;

      .table-input-outline {
        transition: all .1s;

        &.active {
          z-index: 20;
          position: absolute;
          top: -2px;
          left: -7px;
          right: -7px;
          border: 1px solid #92B4F6;
          box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
          padding: 12px;
          border-radius: 16px;
          background: white;
        }
      }

      //.table-item-input__wrapper {


      .table-item-value {
        outline: none;
        border: none;
        transition: all .1s;
        background: transparent;

        &:focus-visible {
          padding: 6px 12px;
          border: 1px solid #D4D9E2;
          border-radius: 8px;
          outline: none;
        }

        &:focus {
          padding: 6px 12px;
          border: 1px solid #D4D9E2;
          border-radius: 8px;
          outline: none;
        }
      }
    }

    .table-item__error {
      background: rgba(253, 229, 236, 0.3);

      .table-item-value:focus-visible {
        border: 1px solid #DF2059;
      }
    }

    .table-item__active {
      background: none;
    }
  }
}

.mass-operations-table {
  .table-row {
    height: 52px;
    border-bottom: 1px solid rgba(167, 193, 235, 0.3);

    .table-phone {
      max-width: 176px;
      width: 100%;
    }

    .table-second-name {
      max-width: 209px;
      width: 100%;
    }

    .table-first-name {
      max-width: 199px;
      width: 100%;
    }

    .table-email {
      max-width: 252px;
      width: 100%;
    }

    .table-summ {
      max-width: 152px;
      width: 100%;
    }

    .table-commission {
      max-width: 174px;
      width: 100%;
    }

    .table-status {
      max-width: 134px;
      width: 100%;
    }

    .table-item p {
      @extend %one-line;
      display: block;
      width: 80%;
    }
  }
}