.mass-desc-container {
  .desc-item {
    height: 54px;
    padding-bottom: 9px;
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }

    .desc-label {
      margin-bottom: 8px;
    }
  }
}


.mass-operations__screen {
  padding: 60px 0;
}
