
.custom-vidi-dropdown {
  position: relative;
  .custom-vidi-dropdown-trigger {
    cursor: pointer;
  }
  .custom-vidi-menu-dropdown {
    z-index: 5000;
    position: absolute;
    top: 40px;
    display: flex;
    flex-direction: column;
    right: -100px;
    transition: 0.3s height ease-in-out, 0.3s opacity ease-in-out;
    background: white;
    opacity: 1;
    pointer-events: all;
    height: inherit;
    padding: 12px 0;
    max-width: 190px;
    min-width: 190px;
    border-radius: 8px;
    box-shadow: 0 4px 60px rgb(0 0 0 / 8%);
    & .button-dropdown_wrapper {
      opacity: 1;
      padding: 10px 16px;
      transition: 0.3s opacity ease-in-out, .3s padding ease-in-out;
      pointer-events: all;
    }
    &.wallet-expanded {
      max-width: 350px;
      min-width: 350px;
    }
    &.hide {
      height: 0;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s opacity ease-in-out, 0.3s height ease-in-out;
      & .button-dropdown_wrapper {
        transition: 0.3s opacity ease-in-out, .3s padding ease-in-out;
        opacity: 0;
        padding: 0 16px;
        pointer-events: none;
      }
    }
  }
}
