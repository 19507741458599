.footer {
  background-color: #FFFFFF;
  border-top: 1px solid #E6E9F0;

  .container {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 56px;
    padding-bottom: 72px;

    .left-section {
      width: 282px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          margin-right: 12px;
        }
      }

      a {
        margin-top: 10px;
        text-decoration: none;
        cursor: pointer;

        img {
          margin-right: 5px;
        }
      }

      a:hover {
        background-color: unset !important;
        color: #707A83;
      }

      .company-name {
        text-transform: uppercase;
        margin: 14px 0;
      }
    }

    .right-section {
      /*width: 270px;*/
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;

      .footer-social {
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          text-decoration: none;
          cursor: pointer;
          margin-right: 15px;
        }

        a:last-child {
          margin-right: 0;
        }

        a:hover {
          background-color: unset !important;
          color: #707A83;
        }
      }

      .bottom-section {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .payments-method {
          img {
            margin-right: 21px;
          }

          img:last-child {
            margin-right: 0;
          }
        }

        p {
          margin-top: 20px;
        }

      }



    }

  }
}

// -----------------------------------
//          breakpoint: md - ≥768px
//------------------------------------
@include media-breakpoint-down(md) {

  .landing-footer {
    .container {
      justify-content: center;
      padding-top: 60px;
      padding-bottom: 60px;

      .right-section {
        margin-top: 20px;
        width: 282px;
      }
    }
  }
}