.transfer-wrapper {
  padding: 24px;
  background: #F5F7FA;
  border-radius: 12px;
  width: 746px;
  .qr-code {
    min-width: 142px;
    max-width: 142px;
    max-height: 142px;
    min-height: 142px;
    width: 142px;
    height: 142px;
    display: flex;
  }
  .usdt-description-wrapper {
    margin-left: 24px;
  }
  .crypt-address {
    margin-right: 24px;
  }
}