//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//    5. Custom colors
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color

$primary:    #0d6efd;
$secondary:  #6c757d;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
$light:      #f8f9fa;
$dark:       #212529;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$grid-breakpoints: (
        xs: 0,
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
);

$container-max-widths: (
        sm: 516px,
        md: 696px,
        lg: 936px,
        xl: 1116px,
        xxl: 1296px,
);

//---------------------------------------------
// 5. Custom colors
//---------------------------------------------

$--ui-color-blue: #4482FF;
$--ui-color-blue-dark: #1849A9;
$--ui-color-blue-med: #92b4f6;
$--ui-color-blue-med-light: #E5ECF9; // #A7C1EB; opacity 0.3
$--ui-color-blue-light: #EEF4FF;
$--ui-color-blue-bg: #e9f0fd;

$--ui-color-500: #172133;
$--ui-color-450: #263238;
$--ui-color-400: #57626D;
$--ui-color-300: #707A83;

$--ui-color-inversive-500: #ffffff;
$--ui-color-inversive-400: #f5f7fa;

$--ui-color-auth-background: #f8f8f8;

$--ui-color-black-light: #48556e;
$--ui-color-gray-dark: #8d95a5;
$--ui-color-gray: #d4d9e2;
$--ui-color-gray-light: #e6e9f0;
$--ui-color-grey-med: #8D95A5;


$--ui-color-green: #3AC267;
$--ui-color-green-dark: #268045;
$--ui-color-green-light: #93DDAC;
$--ui-color-green-bg: rgba(223, 250, 232, 0.3);



$--ui-color-red: #DF2059;
