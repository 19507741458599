
.mr-16 {
  margin-right: 16px;
}

.mr-12 {
  margin-right: 16px;
}

.mr {
  &-8 {
    margin-right: 8px;
  }
  &-12 {
    margin-right: 12px;
  }
  &-16 {
    margin-right: 16px;
  }
  &-24 {
    margin-right: 24px;
  }
}

.h {
  &-8 {
    height: 8px;
  }
  &-12 {
    height: 12px;
  }
  &-16 {
    height: 16px;
  }
  &-24 {
    height: 24px;
  }
}

.spacer {
  display: flex;
  height: 1px;
  width: 100%;

  &-56 {
    display: flex;
    height: 56px;
    width: 100%;
  }

  &-60 {
    display: flex;
    height: 60px;
    width: 100%;
  }

  &-50 {
    display: flex;
    height: 50px;
    width: 100%;
  }

  &-48 {
    display: flex;
    height: 48px;
    width: 100%;
  }

  &-40 {
    display: flex;
    height: 40px;
    width: 100%;
  }

  &-32 {
    display: flex;
    height: 32px;
    width: 100%;
  }

  &-26 {
    display: flex;
    height: 26px;
    width: 100%;
  }

  &-24 {
    display: flex;
    height: 24px;
    width: 100%;
  }

  &-16 {
    display: flex;
    height: 16px;
    width: 100%;
  }

  &-12 {
    display: flex;
    height: 12px;
    width: 100%;
  }

  &-8 {
    display: flex;
    height: 8px;
    width: 100%;
  }

  &-6 {
    display: flex;
    height: 6px;
    width: 100%;
  }

  &-10 {
    display: flex;
    height: 10px;
    width: 100%;
  }
}